@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.bg-none {
  background: none;
  --background: none;
}

ion-segment-button.weeks-button {
  --background-checked: var(--ion-color-primary);
  --indicator-color: var(--ion-color-primary);
  --color-checked: var(--ion-color-primary-contrast);
}

ion-toast {
  font-weight: 800;
}

ion-input.input-hybrid {
  --padding-top: 0px !important;
  --padding-bottom: 0px !important;
  height: 20px !important;
}

.leaderboard-points {
  color: green;
  font-weight: 500;
}

.green {
  color: var(--ion-color-success) !important;
}

.white {
  color: white !important;
}

.branded-title {
  font-family: 'Poppins', sans-serif;
  font-size: 36px;
  font-weight: 800;
  color: #ffc107 !important;
}

.padding-top-small {
  padding-top: 6px;
}
