/* When picking a team, this greys out the other team */
.greyed {
  opacity: 0.5;
  transition: all 200ms ease-in-out;
  filter: grayscale(100%);
}

.game-card {
  min-width: 300px;
  max-width: 600px;
  margin-top: 8px;
}

/* TODO: Change the colors values to take in props of team colors */
.colorful-home {
  background-image: linear-gradient(
    206deg,
    #b0b7bc 0%,
    #0076b6 100%
  ) !important;
}

.colorful-away {
  background-image: linear-gradient(
    206deg,
    #ffb612 0%,
    #97233f 100%
  ) !important;
}

.team-name {
  font-size: 20px;
  font-weight: 800;
  color: var(--ion-color-dark);
}

.game-time {
  color: var(--ion-color-medium);
}

.team-card {
  transition: transform 150ms ease-in-out;
}
.team-card:hover {
  transform: scale(0.95);
}

.correct {
  border: 3px solid var(--ion-color-success);
}

.incorrect {
  border: 3px solid var(--ion-color-warning);
}

.pick-outcome {
  position: absolute;
  top: -5%;
}
