.firebase-emulator-warning {
  display: none !important;
}

.m-0-auto {
  margin: 0 auto;
}

.m-t-6 {
  margin-top: 6px;
}

.max-width-400 {
  max-width: 400px;
}

.branded-title {
  font-family: 'Poppins', 'sans-serif' !important;
  font-size: 36px;
  font-weight: 800;
  color: #ffc107 !important;
}

.full-width {
  width: 100%;
}

@media (prefers-color-scheme: dark) {
  .main-cta {
    background: var(--ion-color-secondary);
  }
}

.white {
  color: #ffffff;
}

.z-index-0 {
  z-index: 0;
}

@media (min-width: 576px) {
  .no-margin {
    margin: 0 auto !important;
  }
}

.disable-pointer-events {
  pointer-events: none;
}

.rounded {
  border-radius: 8px;
}
