@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
/* purgecss start ignore */

.firebase-emulator-warning {
  display: none !important;
}

.m-0-auto {
  margin: 0 auto;
}

.m-t-6 {
  margin-top: 6px;
}

.max-width-400 {
  max-width: 400px;
}

.branded-title {
  font-family: 'Poppins', 'sans-serif' !important;
  font-size: 36px;
  font-weight: 800;
  color: #ffc107 !important;
}

.full-width {
  width: 100%;
}

@media (prefers-color-scheme: dark) {
  .main-cta {
    background: var(--ion-color-secondary);
  }
}

.white {
  color: #ffffff;
}

.z-index-0 {
  z-index: 0;
}

@media (min-width: 576px) {
  .no-margin {
    margin: 0 auto !important;
  }
}

.disable-pointer-events {
  pointer-events: none;
}

.rounded {
  border-radius: 8px;
}

/* purgecss end ignore */

/* purgecss start ignore */

/* When picking a team, this greys out the other team */

.greyed {
  opacity: 0.5;
  transition: all 200ms ease-in-out;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

.game-card {
  min-width: 300px;
  max-width: 600px;
  margin-top: 8px;
}

/* TODO: Change the colors values to take in props of team colors */

.colorful-home {
  background-image: linear-gradient(
    206deg,
    #b0b7bc 0%,
    #0076b6 100%
  ) !important;
}

.colorful-away {
  background-image: linear-gradient(
    206deg,
    #ffb612 0%,
    #97233f 100%
  ) !important;
}

.team-name {
  font-size: 20px;
  font-weight: 800;
  color: var(--ion-color-dark);
}

.game-time {
  color: var(--ion-color-medium);
}

.team-card {
  transition: -webkit-transform 150ms ease-in-out;
  transition: transform 150ms ease-in-out;
  transition: transform 150ms ease-in-out, -webkit-transform 150ms ease-in-out;
}

.team-card:hover {
  -webkit-transform: scale(0.95);
  transform: scale(0.95);
}

.correct {
  border: 3px solid var(--ion-color-success);
}

.incorrect {
  border: 3px solid var(--ion-color-warning);
}

.pick-outcome {
  position: absolute;
  top: -5%;
}

/* purgecss end ignore */

/* purgecss start ignore */

/* purgecss end ignore */
/* purgecss start ignore */

@media only screen and (max-width: 600px) {
  /* TODO: Test for on screen keyboard compatibility */

  .group-modal > .modal-wrapper {
    height: 90%;
    bottom: 0;
    position: absolute;
  }
}

/* purgecss end ignore */

/* purgecss start ignore */

/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/** Ionic CSS Variables **/

:root {
  /** primary **/
  --ion-color-primary: #ffc409;
  --ion-color-primary-rgb: 255, 196, 9;
  --ion-color-primary-contrast: #000000;
  --ion-color-primary-contrast-rgb: 0, 0, 0;
  --ion-color-primary-shade: #e0ac08;
  --ion-color-primary-tint: #ffca22;
  /** secondary **/
  --ion-color-secondary: #3dc2ff;
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #36abe0;
  --ion-color-secondary-tint: #50c8ff;
  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;
  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;
  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;
  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;
  --ion-color-dark: #f4f5f8;
  --ion-color-dark-rgb: 244, 245, 248;
  --ion-color-dark-contrast: #000000;
  --ion-color-dark-contrast-rgb: 0, 0, 0;
  --ion-color-dark-shade: #d7d8da;
  --ion-color-dark-tint: #f5f6f9;
  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152, 154, 162;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0, 0, 0;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;
  --ion-color-light: #222428;
  --ion-color-light-rgb: 34, 36, 40;
  --ion-color-light-contrast: #ffffff;
  --ion-color-light-contrast-rgb: 255, 255, 255;
  --ion-color-light-shade: #1e2023;
  --ion-color-light-tint: #383a3e;
}

.ios body {
  --ion-background-color: #000000;
  --ion-background-color-rgb: 0, 0, 0;
  --ion-text-color: #ffffff;
  --ion-text-color-rgb: 255, 255, 255;
  --ion-color-step-50: #0d0d0d;
  --ion-color-step-100: #1a1a1a;
  --ion-color-step-150: #262626;
  --ion-color-step-200: #333333;
  --ion-color-step-250: #404040;
  --ion-color-step-300: #4d4d4d;
  --ion-color-step-350: #595959;
  --ion-color-step-400: #666666;
  --ion-color-step-450: #737373;
  --ion-color-step-500: #808080;
  --ion-color-step-550: #8c8c8c;
  --ion-color-step-600: #999999;
  --ion-color-step-650: #a6a6a6;
  --ion-color-step-700: #b3b3b3;
  --ion-color-step-750: #bfbfbf;
  --ion-color-step-800: #cccccc;
  --ion-color-step-850: #d9d9d9;
  --ion-color-step-900: #e6e6e6;
  --ion-color-step-950: #f2f2f2;
  --ion-item-background: #000000;
  --ion-card-background: #1c1c1d;
}

.ios ion-modal {
  --ion-background-color: var(--ion-color-step-100);
  --ion-toolbar-background: var(--ion-color-step-150);
  --ion-toolbar-border-color: var(--ion-color-step-250);
}

/*
   * Material Design Dark Theme
   * -------------------------------------------
   */

.md body {
  --ion-background-color: #121212;
  --ion-background-color-rgb: 18, 18, 18;
  --ion-text-color: #ffffff;
  --ion-text-color-rgb: 255, 255, 255;
  --ion-border-color: #222222;
  --ion-color-step-50: #1e1e1e;
  --ion-color-step-100: #2a2a2a;
  --ion-color-step-150: #363636;
  --ion-color-step-200: #414141;
  --ion-color-step-250: #4d4d4d;
  --ion-color-step-300: #595959;
  --ion-color-step-350: #656565;
  --ion-color-step-400: #717171;
  --ion-color-step-450: #7d7d7d;
  --ion-color-step-500: #898989;
  --ion-color-step-550: #949494;
  --ion-color-step-600: #a0a0a0;
  --ion-color-step-650: #acacac;
  --ion-color-step-700: #b8b8b8;
  --ion-color-step-750: #c4c4c4;
  --ion-color-step-800: #d0d0d0;
  --ion-color-step-850: #dbdbdb;
  --ion-color-step-900: #e7e7e7;
  --ion-color-step-950: #f3f3f3;
  --ion-item-background: #1e1e1e;
  --ion-toolbar-background: #1f1f1f;
  --ion-tab-bar-background: #1f1f1f;
  --ion-card-background: #1e1e1e;
}

/* purgecss end ignore */

/* purgecss start ignore */

.bg-none {
  background: none;
  --background: none;
}

ion-segment-button.weeks-button {
  --background-checked: var(--ion-color-primary);
  --indicator-color: var(--ion-color-primary);
  --color-checked: var(--ion-color-primary-contrast);
}

ion-toast {
  font-weight: 800;
}

ion-input.input-hybrid {
  --padding-top: 0px !important;
  --padding-bottom: 0px !important;
  height: 20px !important;
}

.leaderboard-points {
  color: green;
  font-weight: 500;
}

.green {
  color: var(--ion-color-success) !important;
}

.white {
  color: white !important;
}

.branded-title {
  font-family: 'Poppins', sans-serif;
  font-size: 36px;
  font-weight: 800;
  color: #ffc107 !important;
}

.padding-top-small {
  padding-top: 6px;
}

/* purgecss end ignore */

/* purgecss start ignore */

ion-avatar {
  --border-radius: 50%;
}

/* purgecss end ignore */

